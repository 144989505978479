import { Controller } from '@hotwired/stimulus'

export default class LessonTocController extends Controller {
  static targets = ['toc', 'lessonContent']

  static values = {
    itemClasses: String
  }

  connect () {
    this.buildTocItems()

    this.observer = new window.IntersectionObserver(
      this.activeSection.bind(this),
      { rootMargin: '-15% 0px -80% 0px' }
    )

    this.lessonContentTarget.querySelectorAll('section[data-title]').forEach((section) => {
      this.observer.observe(section)
    })
  }

  disconnect () {
    this.observer.disconnect()
    this.tocTarget.innerHTML = ''
  }

  activeSection (entries) {
    entries.forEach((entry) => {
      const { title } = entry.target.dataset
      const tocItemLink = this.tocTarget.querySelector(`li a[href="#${title}"]`);
      const tocItem = tocItemLink?.parentElement

      if (!tocItem) return

      if (entry.intersectionRatio > 0) {
        tocItemLink.classList.add('active')
      } else {
        tocItemLink.classList.remove('active')
      }
    })
  }

  buildTocItems () {
    this.headings().forEach((heading) => {
      this.tocTarget.insertAdjacentHTML('beforeend', this.tocItem(heading))
    })
  }

  headings () {
    return (
      Array
        .from(this.lessonContentTarget.querySelectorAll('section > h3'))
        .map((heading) => heading)
        .filter((heading) => heading.innerText)
    )
  }

  tocItem(heading) {
    const title = heading.innerText.trim()
    const id = this.slugify(title)

    // Add id to the heading element if it doesn't exist
    if (!heading.id) {
      heading.id = id
    }

    return `
      <li data-title="${id}">
        <a href="#${id}"
           class="text-zinc-400 hover:text-zinc-300 transition-colors duration-100">
          ${title}
        </a>
      </li>
    `
  }

  slugify(text) {
    return text
      .toLowerCase()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '')
  }
}
